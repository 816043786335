<template>
  <div class="conOfSchools fill-height">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <img src="@/assets/img/logo2.png" />
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-end">
            <v-icon> mdi-web </v-icon>
            English
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-text-field
          class="search"
          append-icon="mdi-magnify"
          label="Search"
          solo
          outlined
          hide-details
        ></v-text-field>
        <h3 class="text-center">All Universities</h3>

        <div class="d-flex justify-center align center">
          <ul class="list-style">
            <li :key="uni" v-for="uni in univiristies" class="mb-2">
              {{ uni }}
            </li>
          </ul>
        </div>
      </v-col>
      <v-col cols="8" class="">
        <v-row class="d-flex justify-center align center schools">
          <v-col cols="12">
            <v-row class="uni-row mx-2">
              <div class="uni-txt">Universities</div>
            </v-row>
          </v-col>

          <v-col cols="6">
            <router-link :to="{ name: 'school' }"
              ><div class="school-img">
                <div class="school-txt">American</div>
                <img src="@/assets/img/school1.png" />
              </div>
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link :to="{ name: 'school' }">
              <div class="school-img">
                <div class="school-txt">British</div>

                <img src="@/assets/img/school2.png" />
              </div>
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link :to="{ name: 'school' }">
              <div class="school-img">
                <div class="school-txt">American</div>
                <img src="@/assets/img/school1.png" />
              </div>
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link :to="{ name: 'school' }">
              <div class="school-img">
                <div class="school-txt">British</div>
                <img src="@/assets/img/school3.png" />
              </div>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row class="px-2">
          <div>
            <h3>Filter With</h3>
            <v-select
              :items="budget"
              :label="search.budget"
              v-model="search.budget"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Select location"
              v-model="search.location"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="organizations"
              label="Organizations Type"
              v-model="search.organization"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Filter"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Filter"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Filter"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Filter"
              solo
              outlined
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>
            <v-select
              :items="items"
              label="Filter"
              solo
              outlined
              @mouseover.native="true"
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            ></v-select>

            <div class="d-flex justify-center align center">
              <v-col cols="6" class="pa-1">
                <v-btn
                  class="btn-apply"
                  text
                  @click="$router.push({ path: 'schools' })"
                >
                  Apply
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-btn class="btn-clear" text @click="onClear"> Clear </v-btn>
              </v-col>
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ["New Cairo", "Maadi", "6 October"],
      organizations: ["American", "Deutsch", "British"],

      budget: [
        "10,000 - 20,000 EGP",
        "20,000 - 30,000 EGP",
        "30,000 - 40,000 EGP",
      ],
      univiristies: [
        "Ahram Canadian",
        "British University",
        "Culture & Science City",
        "Future University",
        "Heliopolis University",
        "Misr International",
        "Sinai University",
        "Nile University",
        "New Giza University",
      ],
      search: {
        budget: null,
        location: null,
        organization: null,
      },
    };
  },
  methods: {
    onClear() {
      this.search.budget = null;
      this.search.location = null;
      this.search.organization = null;
    },
    inzializeForm() {
      this.search.budget = this.$route.query.budget || "";
      this.search.location = this.$route.query.location || "";
      this.search.organization = this.$route.query.organization || "";
      console.log("Params: ", this.$route.query);
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilter) != JSON.stringify(query)) {
        this.$router.replace({
          name: "schools",
          query: query,
        });
        this.updatedFilter = query;
      }
    },
    onSearch() {
      this.pathByOaramSearch();
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./_schools.scss";
</style>
